import React,{Fragment} from 'react'
import Carousel from 'react-bootstrap/Carousel';
import './PortfolioCarousel.scss';

const PortfolioCarousel = ({slider, title}) => {
    return (
        <Fragment>
            <Carousel interval={null} controls={slider.length >1 ? true : false} className='carousel-big'>
                {slider.map(
                    img => (
                        <Carousel.Item key={img.id}> 
                            {img.imgUrl ? <img src={img.imgUrl} alt={title} /> : <video controls > <source key={img.id} src={img.videoUrl} type="video/mp4" /></video> }
                    </Carousel.Item>
                    )
                )}
            </Carousel>
        </Fragment>
    )
}

export default PortfolioCarousel;