import React from 'react';
import './Footer.scss';
const Footer = () => {
    return (
        <div className='footer'>
            <div className="container">
                <div className="social-links">
                    <a className='in' href="https://linkedin.com/in/payamkazemi" rel="noopener noreferrer" target="_blank"><i className="fab fa-linkedin-in"></i></a>
                    <a className='gh' href="https://github.com/thisispayam" rel="noopener noreferrer" target="_blank"><i className="fab fa-github"></i></a>
                </div>
                <p>All Rights Reserved  &copy; Payam Kazemi, {(new Date().getFullYear())}</p>

            </div>
           
        </div>
    )
}

export default Footer
