import React from 'react';
import Image from 'react-bootstrap/Image';
import './About.scss';
import Nasa from '../../components/Nasa/Nasa';
const About = () => {

    return (
        <div className='about'>  
            <div className="container">
                <div className='context'>
                        <p className='text'><span>Hello! My name is Payam <span className='name'>(Pie-yumm)!</span> <br /></span>
                                I have a BSc in UX design and I have a deep passion when it comes to web development. I'm a web developer who loves (and have to) learn everyday.
                                Some of my hobbies are hiking, baseball/softball, soccer, and ping pong. </p>
                                <br/>
                        <div className="skills">
                                <b className='title'>Skills:</b>
                                <ul>
                                    <li>HTML</li>
                                    <li>CSS (LESS/SASS/PostCSS)</li>
                                    <li>JavaScript, ES2015, jQuery</li>
                                    <li>React, GatsbyJS</li>
                                    <li>NodeJS, npm</li>
                                    <li>Jira, Git, Agile, Scrum</li>
                                    <li>Bootstrap, React Bootstrap</li>
                                    <li>Wordpress, Headless CMS</li>
                                    <li>Photoshop</li>
                                    <li>Sketch, Figma</li>
                                    <li>Making friends</li>
                                </ul>
                        </div>
                    </div>
                    <div className="img">
                        <Image className='about-img' src="https://payam.ca/img/me5.jpg" alt='Payam Kazemi' thumbnail />
                    </div>
            </div>
            {/* <Nasa dataSource="https://api.nasa.gov/planetary/apod?api_key=JHW4xlkZxZ8Y0LCWYu8OcpOGBosjdrjhqLCL6RnU" /> */}
        </div>
    )
}

export default About
