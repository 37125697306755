import React from 'react';
import CardDeck from 'react-bootstrap/CardDeck';
import PortfolioItem from '../../components/PortfolioItem/PortfolioItem';
import data from '../../data.json';
import './Portfolio.scss';
// import {Link} from 'react-router-dom';

const Portfolio = () => {
        return (
            <div className='container'>  
                <p className='text none'>Hello! My name is Payam!
                    I have a BSc in UX design and I have a deep passion when it comes to web development. I'm a developer who loves (and have to) learn everyday.
                                Some of my hobbies are hiking, baseball/softball, soccer, and ping pong. </p>
                <CardDeck>  
                    {data.portfolios.map(
                        ({
                         _id, 
                         title,
                         imageUrl, 
                         tools, 
                         company, 
                         slider, 
                         description,
                         portfolioUrl,
                         github,
                         files 
                         }) => 
                         <PortfolioItem 
                            key={_id} 
                            title={title} 
                            slider={slider} 
                            id={_id} 
                            imageUrl={imageUrl}
                            tools={tools} 
                            company={company} 
                            description={description} 
                            portfolioUrl={portfolioUrl}
                            github={github}
                            files={files} />)}
                </CardDeck>   
            </div>
        )   
}

export default Portfolio;
