import React from 'react'
import {Card} from 'react-bootstrap';
import PortfolioDetailModal from '../../pages/PortfolioDetailModal/PortfolioDetailModal';

const PortfolioItem = ({ id, title, imageUrl, tools, company, slider, description, portfolioUrl, github, files}) => {
    return (       
            <Card>
                <Card.Img variant="top" src={imageUrl} alt={title} />
                    <Card.Body>
                    <Card.Title className='title'>{title} <h6 className='sub-title'>{company}</h6></Card.Title>
                        <Card.Text className='tools'>
                            {tools}
                        </Card.Text>
                    </Card.Body>
                    <PortfolioDetailModal title={title} slider={slider} description={description} portfolioUrl={portfolioUrl} github={github} files={files}/>
            </Card>        
    )
}

export default PortfolioItem
