import React from 'react';
import PortfolioCarousel from '../../components/Carousel/PortfolioCarousel.js';


const Detail = ({ title, description, company,slider, portfolioUrl,github, files }) => {     
   return(
    <div className='container'>
        <h2>{title}</h2>
           <PortfolioCarousel  slider={slider} title={title} />
        <p>{description}</p>
        <p>{company}</p>
        <div className='detail-links'>
            <a href={portfolioUrl}>Visit Site</a><br />
            <a href={github}>Github</a><br />
            <a href={files}>Resources</a><br />
        </div>
       
    </div>
      
   )
   
          
}

export default Detail;
