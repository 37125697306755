import React from 'react';

import ImageGallery from 'react-image-gallery';
import photos from '../../photos.json';
import './Photography.scss';


class Photos extends React.Component {

    render() {
       
       const images = photos.pics.map((img) => {
                return (                  
                      {
                          original: `${(img.imageUrl)}`, 
                          thumbnail: `${(img.thumb)}`
                      }
                )
            }
            )
        

        return (
            <ImageGallery items={images} />
        );
    }

}

export default Photos;