import React from "react";
import "babel-polyfill";
import NavBar from "./components/Navbar/NavBar";
import Portfolio from "./pages/Portfolio/Portfolio";
import About from "./pages/About/About";
import Detail from "./pages/Detail/Detail";
import Footer from "./components/Footer/Footer";
// import Photography from './pages/Photography/Photography';
import Photos from "./pages/Photography/Photos";

import { BrowserRouter as Router, Route, Switch } from "react-router-dom";

import "./App.scss";

function App() {
  return (
    <Router>
      <div className="page">
        <NavBar />
        <Switch>
          <Route exact path="/" component={Portfolio} />
          <Route exact path="/about" component={About} />
          <Route exact path="/photography" component={Photos} />
          <Route exact path="/detail" component={Detail} />
          <Route path="/*" component={Portfolio} />
        </Switch>
      </div>
      <Footer />
    </Router>
  );
}

export default App;
