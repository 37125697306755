import React,{Fragment,useState} from 'react';
import Modal from 'react-bootstrap/Modal';
import PortfolioCarousel from '../../components/Carousel/PortfolioCarousel';
import './PortfolioDetailModal.scss';

const PortfolioDetail = ({title, description, slider, company, portfolioUrl, github, files}) => {      
         const [show, setShow] = useState(false);
            return (
        <Fragment>
                <div className='more' variant="primary" onClick={() => setShow(true)}></div>

                <Modal
                    show={show}
                    onHide={() => setShow(false)}
                    dialogClassName="modal-90w"
                    aria-labelledby="example-custom-modal-styling-title"
                    className="big-modal"
                >
                    <Modal.Header closeButton>
                        <Modal.Title id="example-custom-modal-styling-title">
                            {title}
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body className='container'>
                    <PortfolioCarousel slider={slider} title={title}/>
                        <p>
                        {description}
                        </p>
                        <div className="detail-links">
                                {portfolioUrl ? <a href={portfolioUrl} target='_blank' rel="noopener noreferrer"> Visit site</a> : null}
                                {github ? <a href={github} target='_blank' rel="noopener noreferrer"> Github</a> : null}
                                {files ? <a href={files} target='_blank' rel="noopener noreferrer"> Slides</a> : null}
                        </div>
                           
                    </Modal.Body>
                </Modal>
            </Fragment>
    ); 
          
}

export default PortfolioDetail;
