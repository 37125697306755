import React from 'react';
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
// import NavDropdown from 'react-bootstrap/NavDropdown';
// import Figure from 'react-bootstrap/Figure';
import {Link} from 'react-router-dom';
import './NavBar.scss';

const NavBar = () => {
    return (
        <Navbar bg="light" expand="lg">
            <div className="container">
                <Link className="logo" to="/"><span className='title'>PAYAM KAZEMI</span> <br /> <h2 className='sub-title'>Front-end / UX dev</h2></Link>
                <Navbar.Toggle aria-controls="basic-navbar-nav" />
                <Navbar.Collapse id="basic-navbar-nav">
                    <Nav className="mr-auto float-r nav-items">
                        <Link className='nav-link' to="/">Work</Link>
                        <Link className='nav-link' to="/photography">Photography </Link>
                        
                        {/* About page - Option 1 */}
                        <Link className='nav-link' to="/about">About</Link>

                        {/* About page - Option 2 */}
                        {/* <NavDropdown title="About" id="basic-nav-dropdown" className="about-dropdown">
                            <NavDropdown.Item >
                                <Figure>
                                    <Figure.Image
                                        width={171}
                                        height={180}
                                        alt="171x180"
                                        src="https://payam.ca/img/thumb.jpg"
                                    />
                                    <Figure.Caption>
                                        Hey! "I'm the dude!"
                                    </Figure.Caption>
                                </Figure>
                            </NavDropdown.Item>
                            <NavDropdown.Divider />
                            <div className='social-links'>
                                <NavDropdown.Item className='in' href="https://linkedin.com/in/payamkazemi" target="_blank"><i class="fab fa-linkedin-in"></i></NavDropdown.Item>
                                <NavDropdown.Item className='gh' href="https://github.com/thisispayam" target="_blank"><i class="fab fa-github"></i></NavDropdown.Item>
                            </div>
                           
                        </NavDropdown> */}
                    </Nav>
                </Navbar.Collapse>
            </div>         
        </Navbar>
    )
}

export default NavBar;
