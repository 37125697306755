import React from 'react';
import Accordion from 'react-bootstrap/Accordion';
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import withData from '../../WithData';
import './Nasa.scss';

const Nasa = ({data}) => (
    <div className='content'>
    {/* there is no array to loope trough in this specific api - add the link
    in this format:
    <Nasa dataSource="https://api.nasa.gov/planetary/apod?api_key=JHW4xlkZxZ8Y0LCWYu8OcpOGBosjdrjhqLCL6RnU" /> */}
    {/* {data.map(post => ( */}
        <Accordion>
            <Card>
                <Card.Header>
                    <Accordion.Toggle as={Button} variant="link" eventKey="0">
                        Astronomy Picture of the Day
                    </Accordion.Toggle>
                </Card.Header>
                <Accordion.Collapse eventKey="0">
                    <Card.Body>
                        <h4><span>{data.title}</span></h4>
                        <div className='post' >
                            <img src={data.url} alt={data.title} />
                            <p>{data.explanation} -<span className="sub"> Taken from Nasa APIs</span></p>
                        </div>
                    </Card.Body>
                </Accordion.Collapse>
            </Card>
        </Accordion>
    {/* ))} */}
    </div>
);

export default withData(Nasa);

